





























import { LayoutActions, LayoutGetters } from '@/store/modules/layout';
import { OpenTradeVizOptions, SettingsActions, SettingsGetters } from '@/store/modules/settings';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const layoutNs = namespace('layout');
const uiSettingsNs = namespace('uiSettings');

@Component({})
export default class Template extends Vue {
  @layoutNs.Getter [LayoutGetters.getLayoutLocked]: boolean;

  @layoutNs.Action [LayoutActions.setLayoutLocked];

  @uiSettingsNs.Getter [SettingsGetters.openTradesInTitle]: string;

  @uiSettingsNs.Getter [SettingsGetters.timezone]: string;

  @uiSettingsNs.Action [SettingsActions.setOpenTradesInTitle];

  @uiSettingsNs.Action [SettingsActions.setTimeZone];

  openTradesOptions = [
    { value: OpenTradeVizOptions.showPill, text: 'Show pill in icon' },
    { value: OpenTradeVizOptions.asTitle, text: 'Show in title' },
    { value: OpenTradeVizOptions.noOpenTrades, text: "Don't show open trades in header" },
  ];

  // Careful when adding new timezones here - eCharts only supports UTC or user timezone
  timezoneOptions = ['UTC', Intl.DateTimeFormat().resolvedOptions().timeZone];

  get timezoneLoc() {
    return this.timezone;
  }

  set timezoneLoc(value: string) {
    this[SettingsActions.setTimeZone](value);
  }

  get openTradesVisualization() {
    return this.openTradesInTitle;
  }

  set openTradesVisualization(value: string) {
    this.setOpenTradesInTitle(value);
  }

  get layoutLockedLocal() {
    return this.getLayoutLocked;
  }

  set layoutLockedLocal(value: boolean) {
    this.setLayoutLocked(value);
  }
}



























































































































import { formatPrice } from '@/shared/formatters';

import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout';

import DailyChart from '@/components/charts/DailyChart.vue';
import HourlyChart from '@/components/charts/HourlyChart.vue';
import CumProfitChart from '@/components/charts/CumProfitChart.vue';
import TradesLogChart from '@/components/charts/TradesLog.vue';
import DraggableContainer from '@/components/layout/DraggableContainer.vue';

import {
  DashboardLayout,
  findGridLayout,
  LayoutActions,
  LayoutGetters,
} from '@/store/modules/layout';
import {
  Trade,
  DailyReturnValue,
  BalanceInterface,
  ProfitInterface,
  DailyPayload,
  BotState,
} from '@/types';
import { BotStoreGetters } from '@/store/modules/ftbot';

const ftbot = namespace('ftbot');
const layoutNs = namespace('layout');

@Component({
  components: {
    GridLayout,
    GridItem,
    DailyChart,
    HourlyChart,
    CumProfitChart,
    TradesLogChart,
    DraggableContainer,
  },
})
export default class Dashboard extends Vue {
  @ftbot.Getter closedTrades!: Trade[];

  @ftbot.State dailyStats!: DailyReturnValue;

  @ftbot.Getter openTrades!: Array<Trade>;

  @ftbot.State balance!: BalanceInterface;

  @ftbot.Getter [BotStoreGetters.botState]?: BotState;

  @ftbot.State profit!: ProfitInterface;

  @ftbot.State performanceStats!: Array<PerformanceEntry>;

  @ftbot.Action getPerformance;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action getDaily!: (payload?: DailyPayload) => void;

  @ftbot.Action getTrades;

  @layoutNs.Getter [LayoutGetters.getDashboardLayoutSm]!: GridItemData[];

  @layoutNs.Getter [LayoutGetters.getDashboardLayout]!: GridItemData[];

  @layoutNs.Action [LayoutActions.setDashboardLayout];

  @layoutNs.Getter [LayoutGetters.getLayoutLocked]: boolean;

  @ftbot.Action getOpenTrades;

  @ftbot.Action getBalance;

  @ftbot.Action getProfit;

  formatPrice = formatPrice;

  localGridLayout: GridItemData[] = [];

  currentBreakpoint = '';

  get isLayoutLocked() {
    return this.getLayoutLocked || !this.isResizableLayout;
  }

  get isResizableLayout() {
    return ['', 'md', 'lg', 'xl'].includes(this.currentBreakpoint);
  }

  get gridLayout() {
    if (this.isResizableLayout) {
      return this.getDashboardLayout;
    }
    return this.localGridLayout;
  }

  set gridLayout(newLayout) {
    // Dummy setter to make gridLayout happy. Updates happen through layoutUpdated.
  }

  layoutUpdated(newLayout) {
    // Frozen layouts for small screen sizes.
    if (this.isResizableLayout) {
      console.log('newlayout', newLayout);
      console.log('saving dashboard');
      this.setDashboardLayout(newLayout);
    }
  }

  get gridLayoutKPI(): GridItemData {
    return findGridLayout(this.gridLayout, DashboardLayout.KPI);
  }

  get gridLayoutDaily(): GridItemData {
    return findGridLayout(this.gridLayout, DashboardLayout.dailyChart);
  }

  get gridLayoutHourly(): GridItemData {
    return findGridLayout(this.gridLayout, DashboardLayout.hourlyChart);
  }

  get gridLayoutCumChart(): GridItemData {
    return findGridLayout(this.gridLayout, DashboardLayout.cumChartChart);
  }

  get gridLayoutTradesLogChart(): GridItemData {
    return findGridLayout(this.gridLayout, DashboardLayout.tradesLogChart);
  }

  get responsiveGridLayouts() {
    return {
      sm: this.getDashboardLayoutSm,
    };
  }

  mounted() {
    this.getDaily({ timescale: 30 });
    this.getTrades();
    this.getOpenTrades();
    this.getBalance();
    this.getPerformance();
    this.getProfit();
    this.localGridLayout = [...this.getDashboardLayoutSm];
  }

  breakpointChanged(newBreakpoint) {
    // console.log('breakpoint:', newBreakpoint);
    this.currentBreakpoint = newBreakpoint;
  }
}
